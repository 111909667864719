<template>
    <div> 

        <IconTopTable />
        <div class="content-table-results mt-2">
            <table class="table table-fixed table-stripes">
                <ColumnColGroup page="result" :column="data.column" :default_column="data.default_column" />
                <ColumnThead page="result" :column="data.column" :default_column="data.default_column" />
                <tbody> 
                    <template v-if="data.data.data && data.data.data.length > 0">
                        <tr v-for="(item, key) in data.data.data" :key="key">   
                            <td>
                                <label class="table-check">
                                    <input type="checkbox" v-model="select_export" :value="item.drug_id">
                                    <span class="checkbox-table"></span>
                                </label>
                            </td>
                            <td>
                                <router-link :to="'/detail/'+item.drug_id+'/'+item.agency_model.toLowerCase()" target="_blank" rel="noopener">
                                    <div class="icon-fiche"></div>
                                </router-link>
                                <div v-if="isAdmin()">
                                    <a :href="modifyPage(item.agency_model.toLowerCase(), item.drug_id)" target="_blank" rel="noopener"> 
                                        <em class="icon-edit"></em>
                                    </a>
                                </div>
                            </td>
                            <template v-for="(column) in data.column" :key="column.code" >

                                <td v-if="column.Status && column.code == 'italy_001'" :code="column.code">
                                    <span v-html="ema_id(item.links)"></span>                                
                                </td>
                                <td v-else-if="column.Status && column.code == 'italy_002'" :code="column.code">
                                    {{item.drug_id}}
                                </td>
                                <!-- <td v-else-if="column.Status && column.code == 'italy_003'" :code="column.code">
                                    <span v-html="icon_link(item.drug_id, 'italy')"></span>
                                </td> -->
                                <td v-else-if="column.Status && column.code == 'italy_004'" :code="column.code">
                                    {{check_empty(item.category)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'italy_005'" :code="column.code">
                                    {{check_empty(item.dci_new)}}                                 
                                </td>
                                <td v-else-if="column.Status && column.code == 'italy_006'" :code="column.code">
                                    <span v-html="rembursement_status(item.reimbursement_decision, 'rb_')"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'italy_046'" :code="column.code">
                                    <span v-html="check_empty(overview_regulatory_status(item.current_ma_status))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'italy_007'" :code="column.code">
                                    <span v-html="column_status(item)"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'italy_008'" :code="column.code">
                                    {{check_empty(DDMMMYY(item.italy.opinion_date))}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'italy_009'" :code="column.code">
                                    {{check_empty(DDMMMYY(item.ma_date))}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'italy_010'" :code="column.code">
                                    {{check_empty(item.name)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'italy_011'" :code="column.code">
                                    {{check_empty(item.dci)}} 
                                </td>
                                <td v-else-if="column.Status && column.code == 'italy_012'" :code="column.code">                                
                                    {{check_empty(item.firm)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'italy_013'" :code="column.code">
                                    <span v-html="check_empty(array(item.atc))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'italy_014'" :code="column.code">
                                    <span v-html="check_empty(array(item['indexation_'+ $i18n.locale]))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'italy_015'" :code="column.code">
                                    {{ $i18n.locale == 'fr' ? item.agency_fr : item.agency_en }}
                                </td>
                                <td v-else-if="column.Status && column.code == 'italy_016'" :code="column.code">
                                    {{check_empty(item.italy.agency)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'italy_017'" :code="column.code">
                                    <span :class="color_type_limitation(item.prioritis_hta_index)">{{ check_empty(item.prioritis_hta_index)}}</span>
                                </td>
                                <template v-else-if="column.Status && column.code == 'italy_018'" >
                                    <td :key="column.code+'_019'" code="italy_019">
                                        <Readmore :longText="item.target_population" />
                                    </td>                            
                                    <td :key="column.code+'_020'" code="italy_020">                              
                                        <Readmore :longText="item.prescription_and_delivery_conditions" />
                                    </td>                            
                                    <td :key="column.code+'_021'" code="italy_021">
                                         <Readmore :longText="item.access_scheme" />
                                    </td>
                                    <td :key="column.code+'_022'" code="italy_022">
                                        <Readmore :longText="item.outcome_level" />
                                    </td>
                                </template>
                                <td v-else-if="column.Status && column.code == 'italy_023'" :code="column.code">
                                    <Readmore :longText="item.rationale_and_comments_html_en" />
                                </td>
                                <td v-else-if="column.Status && column.code == 'italy_024'" :code="column.code">
                                    {{check_empty(item.italy.drug_class)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'italy_025'" :code="column.code">
                                    <Readmore v-if="item.indication_en" :longText="item.indication_en" />
                                    <Readmore v-else-if="item.indication_original" :longText="item.indication_original" />
                                    <span v-else> - </span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'italy_026'" :code="column.code">                                
                                    <span v-html="check_empty(array(item.classification_en))"></span>
                                </td>  
                                <td v-else-if="column.Status && column.code == 'italy_027'" :code="column.code">
                                    {{check_empty_with_locale(item.mk_orphelin)}}
                                </td>   
                                <td v-else-if="column.Status && column.code == 'italy_028'" :code="column.code">                                
                                    <span v-html="mea(item )"></span>
                                </td>   
                                <td v-else-if="column.Status && column.code == 'italy_029'" :code="column.code">                                
                                    <span v-html="law_648(item.italy.law_648) "></span>
                                </td>   
                                <td v-else-if="column.Status && column.code == 'italy_030'" :code="column.code">
                                    {{check_empty(item.italy.therapeutic_innovation )}}
                                </td>   
                                <td v-else-if="column.Status && column.code == 'italy_031'" :code="column.code">
                                    {{check_empty(item.reimbursement_decision)}}
                                </td>   
                                <td v-else-if="column.Status && column.code == 'italy_032'" :code="column.code">
                                    {{check_empty(DDMMMYY(item.italy.gu_date))}}
                                </td>   
                                <td v-else-if="column.Status && column.code == 'italy_033'" :code="column.code">
                                    <span v-html="nct_number(item.nct_numbers)" @click="drug_id = item.drug_id"></span>
                                    <!-- modal_clinical_trial -->
                                </td>   
                                <td v-else-if="column.Status && column.code == 'italy_034'" :code="column.code">
                                <span v-html="check_empty(treatment_line(item.treatment_line))"></span>
                                </td>   
                                <td v-else-if="column.Status && column.code == 'italy_035'" :code="column.code">
                                    {{check_empty(item.specificity)}}
                                </td>   
                                <td v-else-if="column.Status && column.code == 'italy_036'" :code="column.code">
                                    {{check_empty(item.administration)}}
                                </td>   
                                <template v-else-if="column.Status && column.code == 'italy_037'">                         
                                    <td  :key="column.code+'_038'" code="italy_038">
                                        {{check_empty(DDMMMYY(item.hta_process_date)) }}
                                    </td>   
                                    <td  :key="column.code+'_039'" code="italy_039">                            
                                        <span v-html="hta_process_status(item,'hta_')"></span>
                                    </td>   
                                    <td  :key="column.code+'_040'" code="italy_040">                                
                                        {{check_empty(DDMMMYY(item.rembursement_process_date))}}
                                    </td>     
                                    <td  :key="column.code+'_041'" code="italy_041">                               
                                        <span v-html="rembursement_status(item.reimbursement_decision, 'rb_')"></span>
                                    </td>     
                                </template>
                                <template v-else-if="column.Status && column.code == 'italy_042'">                                   
                                    <td  :key="column.code+'_043'" code="italy_043" >
                                        <span data-bs-toggle="modal" data-bs-target="#modal-hta-status" @click="hta_status_modal(item)" v-html="hta_process_status_2(item)"></span>
                                    </td>     
                                    <td  :key="column.code+'_044'" code="italy_044" >
                                        <span data-bs-toggle="modal" data-bs-target="#modal-hta-status" @click="hta_status_modal(item)" v-html="rembursement_status_2(item)"></span>                                     
                                    </td>     
                                    <td  :key="column.code+'_045'" code="italy_045">                                    
                                        {{datediff(item.ma_date, item.rembursement_process_date)}}                          
                                    </td>     
                                </template>
                            </template>
                        </tr>
                    </template>

                    <template v-else><tr><td colspan="99">{{$t('No Data')}}</td></tr></template>
                    
                </tbody>
            </table>
        </div>   
        <ModalHTAstatus :item="item_hta_status" />
        <ModalEssaisClinique :id="drug_id" agency="Italy" />    
    </div>
</template>

<script>

import IconTopTable from '@/components/result/icon_top_table.vue'
import ColumnColGroup from '@/components/elements/column_colgroup.vue'
import ColumnThead from '@/components/elements/column_thead.vue'
import Readmore from '@/components/elements/readmore.vue'
import ModalHTAstatus from '@/components/result/modal_hta_status.vue'
import { isAdmin, column_status, hta_process_status, hta_process_status_2, rembursement_status, 
        rembursement_status_2, overview_regulatory_status, modifyPage, ema_id, icon_link, DDMMMYY,
        check_empty, nct_number, treatment_line, datediff, color_type_limitation, array, check_empty_with_locale } from '@/utils'
import ModalEssaisClinique from '@/components/elements/modal_essais_clinique.vue'
export default {
    name: "Italy",
    components: {
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        Readmore,
        ModalHTAstatus,
        ModalEssaisClinique
    },
    data(){
        return{ 
            item_hta_status : {},
            drug_id : 0 //for essais clinique popup
        }
    },
     computed: {                
        data(){
             return this.$store.getters['result/data'];
        },
        select_export: {
            set(val){ this.$store.dispatch("result/select_export", val) },
            get(){ 
                if(this.$store.getters['result/select_export_checkAll']){
                    return true
                }else{
                    return this.$store.getters['result/select_export'] ? this.$store.getters['result/select_export'].listID : null 
                }
            }
        }
    },
    methods: {
         mea : function(param){   
             let txt = ''   
             if(param.italy.italy_mea){        
                for(const val of param.italy.italy_mea){                    
                   txt += val.mea  ? `<p>${val.mea}</p>` : ""
                }       
            }
           
            return  (txt.length == '') ? '-' : txt
            
         },
         law_648 : function(param){   
            let txt = ''   
            txt +=  ( param == '1' ) ? `<p>Yes</p>` : ""
            return  (txt.length == 0) ? '-' : txt
         },
        hta_process_status,
        rembursement_status,
        rembursement_status_2,
        hta_process_status_2,
        column_status,
        isAdmin,
        ema_id,
        icon_link,
        DDMMMYY,
        check_empty,
        nct_number,
        treatment_line,
        datediff,
        color_type_limitation,
        array,
        check_empty_with_locale,
        getResults(page = 1) {
            console.log(page)
        },
        hta_status_modal(item){
            this.item_hta_status = item
        },
        overview_regulatory_status,
        modifyPage
        
    },
   
}
</script>

<style scoped>

/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 70vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}
</style>
